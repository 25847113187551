import htmx from 'htmx.org';

// Configuration
htmx.config.requestClass = 'is-requesting';
htmx.config.allowEval = false;

// Enable lazyloading of swapped/inserted elements
htmx.on('htmx:afterSwap', (event) => {
  event.target
    .querySelectorAll('noscript.loading-lazy')
    .forEach(($noscript) => {
      while ($noscript.firstChild) {
        $noscript.parentNode.insertBefore($noscript.firstChild, $noscript);
      }

      $noscript.parentNode.removeChild($noscript);
    });
});
