import { on } from 'delegated-events';
import { createFocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import collapse from '../../../javascripts/utils/collapse';
import invisibleFocus from '../../../javascripts/utils/invisible-focus';
import moveFocus from '../../../javascripts/utils/move-focus';

let focusTrap = null;

on('click', '.header__menu-button--open', async (event) => {
  const { currentTarget: $trigger } = event;

  event.preventDefault();

  const { $target } = await collapse($trigger, true);

  focusTrap = createFocusTrap($target, {
    initialFocus: '.header__menu-topic',
    clickOutsideDeactivates: true,
    returnFocusOnDeactivate: false,
    onActivate() {
      // Use invisible focus on start
      moveFocus($target.querySelector('.header__menu-topic'));

      // Disable scrolling
      const $innerScroll = $target.querySelector('.header__menu-flyout-inner');
      disableBodyScroll($innerScroll);
    },
    async onDeactivate() {
      // Close navigation
      await collapse($trigger, false);

      // Re-enable scrolling
      const $innerScroll = $target.querySelector('.header__menu-flyout-inner');
      enableBodyScroll($innerScroll);

      // Move focus back to open button
      invisibleFocus($trigger);
    },
  });

  focusTrap.activate();
});

on('click', '.header__menu-button--close', async (event) => {
  event.preventDefault();

  if (focusTrap) {
    focusTrap.deactivate();
    focusTrap = null;
  }
});
