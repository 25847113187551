import onBlur from '../../../javascripts/utils/on-blur';

document.addEventListener(
  'touchstart',
  (event) => {
    if (!event.target.closest('.profile-teaser')) {
      return;
    }

    const $profileTeaser = event.target.closest('.profile-teaser');
    const isActive = $profileTeaser.classList.contains(
      'profile-teaser--active',
    );

    if (isActive) {
      return;
    }

    event.preventDefault();

    $profileTeaser.classList.add('profile-teaser--active');

    onBlur($profileTeaser, () => {
      $profileTeaser.classList.remove('profile-teaser--active');
    });
  },
  {
    passive: false,
  },
);
