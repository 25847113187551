import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/when-ready';
import elementIndex from '../../../javascripts/utils/element-index';

const moveGallery = ($gallery, value = 0, relative = true) => {
  const currentSlide = parseInt($gallery.dataset.currentSlide, 10) || 1;
  const $$slide = $gallery.querySelectorAll('.gallery__image');
  const nextSlide = relative ? currentSlide + value : value;

  // Out of range
  if (nextSlide === 0 || nextSlide > $$slide.length || $$slide.length === 1) {
    return;
  }

  // Update scroll track
  const $track = $gallery.querySelector('.gallery__images');
  const $slide = $$slide[nextSlide - 1];
  $track.scrollLeft = $slide.offsetLeft;
  $gallery.dataset.currentSlide = nextSlide; // eslint-disable-line no-param-reassign

  // Update buttons
  const $prevButton = $gallery.querySelector(
    '.gallery__navigation-button--prev',
  );
  const $nextButton = $gallery.querySelector(
    '.gallery__navigation-button--next',
  );

  $prevButton.disabled = nextSlide === 1;
  $nextButton.disabled = nextSlide === $$slide.length;
};

whenReady().then(() => {
  const $$track = document.querySelectorAll('.gallery__images');
  $$track.forEach(($track) => {
    // eslint-disable-next-line no-param-reassign
    $track.scrollLeft = 0;
  });
});

window.addEventListener(
  'resize',
  () => {
    const $$gallery = document.querySelectorAll('.gallery');
    $$gallery.forEach(($gallery) => moveGallery($gallery));
  },
  false,
);

on('click', '.gallery__navigation-button--prev', (event) => {
  const $gallery = event.target.closest('.gallery');
  moveGallery($gallery, -1);
});

on('click', '.gallery__navigation-button--next', (event) => {
  const $gallery = event.target.closest('.gallery');
  moveGallery($gallery, 1);
});

on('mousedown', '.gallery__image .link', (event) => {
  event.preventDefault();
});

on('focusin', '.gallery__image', (event) => {
  const $slide = event.target.closest('.gallery__image');
  const $gallery = $slide.closest('.gallery');
  const value = elementIndex($slide);

  moveGallery($gallery, value + 1, false);
});

let startPosition = null;

on('touchstart', '.gallery', (event) => {
  const { currentTarget: $gallery } = event;
  startPosition = event.touches[0]?.clientX;

  const onMove = (moveEvent) => {
    const { clientX } = moveEvent?.touches[0];
    const diff = startPosition - clientX;

    if (diff > 100) {
      $gallery.removeEventListener('touchmove', onMove);
      moveGallery($gallery, 1, true);
    } else if (diff < -100) {
      $gallery.removeEventListener('touchmove', onMove);
      moveGallery($gallery, -1, true);
    }
  };

  const onEndOrCancel = () => {
    $gallery.removeEventListener('touchmove', onMove);
    $gallery.removeEventListener('touchend', onEndOrCancel);
    $gallery.removeEventListener('touchcancel', onEndOrCancel);
    startPosition = null;
  };

  if (startPosition) {
    $gallery.addEventListener('touchmove', onMove);
    $gallery.addEventListener('touchend', onEndOrCancel);
    $gallery.addEventListener('touchcancel', onEndOrCancel);
  }
});
