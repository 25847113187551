import onBlur from '../../../javascripts/utils/on-blur';

document.addEventListener(
  'touchstart',
  (event) => {
    if (!event.target.closest('.slide-teaser')) {
      return;
    }

    const $sliderTeaser = event.target.closest('.slide-teaser');
    const isActive = $sliderTeaser.classList.contains('slide-teaser--active');

    if (isActive) {
      return;
    }

    event.preventDefault();

    $sliderTeaser.classList.add('slide-teaser--active');

    onBlur($sliderTeaser, () => {
      $sliderTeaser.classList.remove('slide-teaser--active');
    });
  },
  {
    passive: false,
  },
);
