import whenReady from '../../../javascripts/utils/when-ready';

whenReady().then(() => {
  const $$personSliderScroller = document.querySelectorAll(
    '.people-grid__scroller',
  );

  $$personSliderScroller.forEach(($el) => {
    const rect = $el.getBoundingClientRect();
    $el.scrollLeft = ($el.scrollWidth - rect.width) / 2;
  });
});
