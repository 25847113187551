import { on } from 'delegated-events';

on('click', '.js-show-external-content', (event) => {
  event.preventDefault();

  // Get container
  const $externalContent = event.target.closest('.external-content');
  const $container = $externalContent.querySelector(
    '.external-content__container',
  );

  // Delete preview
  const $preview = $container.querySelector('.external-content__preview');
  $preview.remove();

  // Unpack template with content
  const $content = $externalContent.querySelector('.external-content__content');
  $container.appendChild($content.content.cloneNode(true));

  // Find iframe
  const $iframe = $container.querySelector('iframe');
  if ($iframe && $iframe.width && $iframe.height) {
    $iframe.classList.add('external-content__iframe');
    $container.style.paddingTop = `${($iframe.height / $iframe.width) * 100}%`;
  }
});
