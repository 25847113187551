export type BlurEvent = FocusEvent | MouseEvent | TouchEvent;

const onBlur = (
  $target: HTMLElement,
  callback: (e: BlurEvent) => void,
): void => {
  const trigger = (event: BlurEvent) => {
    if (event.target instanceof Element && !$target.contains(event.target)) {
      callback(event);

      document.removeEventListener('blur', trigger);
      document.removeEventListener('mouseup', trigger);
      document.removeEventListener('touchstart', trigger);
    }
  };

  document.addEventListener('blur', trigger);
  document.addEventListener('mouseup', trigger);
  document.addEventListener('touchstart', trigger);
};

export default onBlur;
